export interface CompanionResourceDescriptionInterface {
    name: string,
    img: string,
    behaviour: "dog" | "cat" | "fish"
}

export const COMPANION_RESOURCES: CompanionResourceDescriptionInterface[] = [
    { name: "dog1", img: "resources/characters/pipoya/Dog 01-1.png", behaviour: "dog" },
    { name: "dog2", img: "resources/characters/pipoya/Dog 01-2.png", behaviour: "dog" },
    { name: "dog3", img: "resources/characters/pipoya/Dog 01-3.png", behaviour: "dog" },
    { name: "cat1", img: "resources/characters/pipoya/Cat 01-1.png", behaviour: "cat" },
    { name: "cat2", img: "resources/characters/pipoya/Cat 01-2.png", behaviour: "cat" },
    { name: "cat3", img: "resources/characters/pipoya/Cat 01-3.png", behaviour: "cat" },

    { name: "fish0", img: "resources/characters/companion_fish_blue.png", behaviour: "fish" },
    { name: "fish1", img: "resources/characters/companion_fish_green.png", behaviour: "fish" },
    { name: "fish2", img: "resources/characters/companion_fish_red.png", behaviour: "fish" },
    { name: "shark0", img: "resources/characters/companion_sharks_green.png", behaviour: "fish" },
    { name: "shark1", img: "resources/characters/companion_sharks_grey.png", behaviour: "fish" },
    { name: "shark2", img: "resources/characters/companion_sharks_orange.png", behaviour: "fish" },
    { name: "shark3", img: "resources/characters/companion_sharks_tile_green.png", behaviour: "fish" },
    { name: "jelly1", img: "resources/characters/companion_jelly_anim3.png", behaviour: "fish" },
    { name: "ghost1", img: "resources/characters/companion_pacman_anim.png", behaviour: "fish" },
    { name: "cake1", img: "resources/characters/cake_hop1.png", behaviour: "fish" },
    { name: "bierbierbier", img: "resources/characters/bierkumpel.png", behaviour: "fish" },
    { name: "ballon", img: "resources/characters/companion_ballon_lila.png", behaviour: "fish" },
    { name: "hifish", img: "resources/characters/companion_hifish.png", behaviour: "fish" },
]
